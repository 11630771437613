<script setup lang="ts">
useHead({
  title: "404 - Page Not Found",
});

const { updateCoords } = useMouseCoords();
const router = useRouter();

function handleBack() {
  router.back();
}
</script>

<template>
  <main
    v-mouse-coords="updateCoords"
    class="flex flex-col justify-center items-center w-full h-screen gap-14 bg-[#f3f8fb]"
  >
    <img src="../ckBase/assets/svg/logo.svg" class="w-52 h-16" :alt="$t('alt.logo')" />
    <section
      class="flex flex-col items-center justify-center border-2 border-ngray-6 rounded-lg p-4 min-w-[420px] min-h-[491px] gap-4 bg-white"
    >
      <h1 class="text-nred-3 text-[115px] font-extrabold leading-none">404</h1>

      <h3 class="text-nblue-1 text-lg font-bold tracking-[5px] leading-[25px]">PAGE NOT FOUND</h3>

      <p class="text-sm font-normal text-nblue-1 w-[230px] text-center">
        Sorry the page you are looking for doesn't exist or has been moved.
      </p>
      <button
        class="text-white bg-nblue-2 rounded-full px-6 py-3 font-semibold text-center"
        type="button"
        @click="handleBack"
      >
        Go Back
      </button>
      <slot />
    </section>
  </main>
</template>
